<template>
  <!-- eslint-disable -->
  <v-container v-if="hasPermission($route.meta.permission)" id="user-profile" tag="section">
      <v-row class="ma-0 ">
    <v-col cols="12" class="d-flex  justify-center ">
      <v-card class="pa-3" width="70%">
        <v-row class="ma-0">
          <v-col cols="12" md="12">
            <v-row class="ma-0">
								<v-col cols="10" sm="4" class="d-flex align-center me-auto">
									<span>Branches <v-icon>mdi-chevron-right</v-icon></span>
								<span class="text-h4 text-sm-h2 ">Edit Branch </span>
										
									</h4>
								</v-col>
              <v-col cols="2" sm="1" class="d-flex align-center justify-end pa-0">
                <v-btn small class="blue-grey lighten-4" fab text icon to="/branches"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-card-text>
          <v-row class="">
            <v-col>
              <v-form v-model="valid">
                <v-text-field
                  label="Name"
                  outlined
                  v-model="name"
                  :rules="nameRules"
                ></v-text-field>
                <gmap-autocomplete
                  placeholder="search for location"
                  class="px-2 py-2 my-4 mx-2 "
                  style="width: 100%; padding:16px !important;margin:0 !important;border:1px solid skyblue !important;border-radius:5px !important"
                  @place_changed="setPlace"
                  :value="address"
                >
                </gmap-autocomplete>
              </v-form>
            </v-col>
         
            <v-col cols="12" class="my-auto">
              <GmapMap
                id="map"
                ref="map"
                :center="{
                  lat: lat ? lat : 26.4110624,
                  lng: long ? long : 50.1089682,
                }"
                :zoom="15"
                map-type-id="terrain"
                style="min-width: 250px; min-height: 250px"
              >
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in markers"
                  :position="m.position"
                  :clickable="true"
                  :draggable="true"
                  @click="center = m.position"
                  @dragend="updateCoordinates"
                />
              </GmapMap>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn class="ms-auto me-0"     :disabled="!valid" color="primary" @click="updateWarehouse">
            <v-icon left>mdi-update</v-icon>
            update Branch
          </v-btn>
     
        </v-card-actions>
      </v-card>
      </v-col>
    </v-col>
    </v-row>
  </v-container>
  <v-container fluid v-else class="pa-10 " id="regular-tables" tag="section">
		<v-row>
			<v-col cols="12" class="d-flex justify-center">
				<v-card flat>
					<v-card-title
						><v-card class="red">
							<h1 class="red pa-6">
								You Don't have Permission to view this page !
							</h1></v-card
						></v-card-title
					>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
/* eslint-disable */
import { gmapApi } from "vue2-google-maps";

export default {
	props: {
		id: String,
	},
	data() {
		return {
			partner_admins: [],
			items: ["Admin", "Viewer", "Member"],
			showLogo: false,
			preview: null,
			image: null,
			item: null,
			nameRules: [(v) => !!v || "Field is required"],
			markers: [],
			map_place: [],
			place: null,
			title: "",
			valid: false,
			action: "add",
			warehouseid: "",
			lat: "",
			long: "",
			name: "",
			mname: {},
			phone: "",
			address: "",
			image: require("@/assets/add-logo.png"),
			branches: [],
			merch_details: [],
			totalAppointment: 0,
			loading: true,
			dialog: false,
			user_id: localStorage.getItem("userid"),
			search: "",
			role: localStorage.getItem("role"),
			options: {},
			headers: [
				{
					text: "Name",
					align: "center",
					width: 120,
					value: "name",
				},
				{
					text: "Manger name",
					align: "center",
					sortable: true,
					width: 120,
					value: "contact_name",
				},

				{
					text: "Phone",
					align: "center",
					sortable: true,
					width: 70,
					value: "contact_phone",
				},

				{
					text: "Address",
					align: "center",
					sortable: true,
					width: 120,
					value: "location",
				},

				{
					text: "Created",
					align: "center",
					sortable: true,
					width: 70,
					value: "created_at",
				},
				{
					text: "Edit",
					align: "center",
					sortable: true,
					width: 70,
					value: "id",
				},
			],
		};
	},
	computed: {
		google: gmapApi,
		searchParams() {
			return {
				query: this.search,
			};
		},
	},
	watch: {
		selected: {
			handler() {
				this.updatepermission();
			},
		},
	},
	mounted() {
		this.getMerchant();
		this.getBranch();
		this.getPartnerAdmins();
	},
	methods: {
		updateWarehouse() {
			axios
				.post(
					`/api/v3/partner/add-branch-partner?spa=1&id=${this.warehouseid}&seller_id=${this.mname.id}&partner_id=${this.user_id}&branch_name=${this.name}&latitude=${this.lat}&longtitude=${this.long}&subvisor_name=${this.mname.name}&subvisor_phone=${this.mname.users_mobile}&location=${this.address}`
				)
				.then((res) => {
					this.getBranch();
					this.$router.push({ path: "/branches" });
				})
				.catch((err) => console.log(err.response.data));
		},

		clearForm() {
			this.markers = [];
			this.selectedAreas = [];
			this.mname = {};
			this.name = "";
			this.phone = "";
			this.address = "";
			this.long = "";
			this.lat = "";
		},

		geocodeLatLng(map) {
			let geocoder = new google.maps.Geocoder();
			let self = this;
			geocoder.geocode(
				{
					latLng: map,
				},
				function (responses) {
					if (responses && responses.length > 0) {
						self.address = responses[0].formatted_address;
						self.lat = map.lat;
						self.long = map.lng;
					} else {
						alert("Cannot determine address at this location.");
					}
				}
			);
		},
		editBranch(item) {
			this.markers = [];
			this.selectedAreas = [];
			this.name = item.name;
			this.warehouseid = item.id;
			this.mname.id = item.seller_id;
			this.mname.name = item.contact_name;
			this.mname.users_mobile = item.contact_phone;
			this.lat = item.latitude;
			this.long = item.longtitude;
			this.address = item.location;
			this.selectedAreas = item.area_select;
			this.markers.push({
				position: {
					lat: item.latitude,
					lng: item.longtitude,
				},
			});
		},
		geocodePosition(pos) {
			let geocoder = new google.maps.Geocoder();
			let self = this;
			geocoder.geocode(
				{
					latLng: pos,
				},
				function (responses) {
					if (responses && responses.length > 0) {
						// alert(responses[0].formatted_address);
						self.address = responses[0].formatted_address;
						self.lat = pos.lat();
						self.long = pos.lng();
					} else {
						alert("Cannot determine address at this location.");
					}
				}
			);
		},
		setPlace(place) {
			this.markers = [];
			this.map_place = place;
			this.lat = place.geometry.location.lat();
			this.long = place.geometry.location.lng();
			this.address = place.formatted_address;
			this.markers.push({
				position: {
					lat: place.geometry.location.lat(),
					lng: place.geometry.location.lng(),
				},
			});
		},
		updateCoordinates(location) {
			this.geocodePosition(location.latLng);
		},
		getMerchant() {
			axios
				.post(`/api/v3/partner/b2b-merchant-info?partner_id=${this.user_id}`)
				.then((res) => {
					this.merch_details = res.data;
					// this.branches = res.data.warehouse;
				})
				.catch((err) => console.log(err.response.data))
				.finally(() => (this.loading = false));
		},
		getBranch() {
			axios
				.get(`/api/v3/all-partners-branches?spa=1&user_id=${this.user_id}`)
				.then((res) => {
					this.branches = res.data.data;
					this.item = this.branches.filter(
						(branch) => branch.id == this.$props.id
					)[0];
					this.totalAppointment = res.data.total;
					this.editBranch(this.item);
				})
				.finally(() => (this.loading = false));

			// get by search
		},
		getPartnerAdmins() {
			// this.company_name = localStorage.getItem('name');
			axios
				.get(
					`/api/v3/get-partner-admins?user_id=${localStorage.getItem(
						"userid"
					)}`,
					{
						headers: { "X-Authorization": this.token },
					}
				)
				.then((res) => {
					this.partner_admins = res.data.partner_admins;
				})
				.catch((err) => {
					if (err.response.data.status_code) {
						this.$router.push("/");
					}
				})
				.finally(() => (this.loading = false));
		},
	},
};
</script>
<style>
/*  */
</style>
